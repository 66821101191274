import {useState} from "react";
import {Tabs} from "antd";

import './utils/ArrayExtension'
import FretboardToPitchName from "./components/FretboardToPitchName/FretboardToPitchName";

const defaultActiveKey = '1';

function App() {
    const [activeKey, setActiveKey] = useState(defaultActiveKey);
    return (
        <div style={{width: "100%", display: "flex", justifyContent: "center"}} className="App">
            <Tabs
                style={{marginLeft: "20px", marginRight: "20px", marginTop: "20px", maxWidth: "100%", minWidth: "50%"}}
                defaultActiveKey={defaultActiveKey}
                activeKey={activeKey}
                onChange={setActiveKey}
                items={[
                    {
                        label: `从品找音`,
                        key: "1",
                        children: <FretboardToPitchName/>,
                    },
                ]}/>
        </div>
    );
}

export default App;
