import {useEffect, useRef, useState} from "react";
import {InputNumber, Button, Checkbox, Input, Divider, Space, Collapse, Image} from "antd";
import {CheckCircleTwoTone, CloseCircleTwoTone} from "@ant-design/icons";

import randomIntFromInterval from "../../utils/randomIntFromInterval";
import answerMap from "../../utils/answerMap";
import guitarNoteNamesImage from "./GuitarNoteNames.gif"

const {Panel} = Collapse;
const defaultInputQuestionNumber = 10;

const stringsOptions = [{
    label: '6', value: '6',
}, {
    label: '5', value: '5',
}, {
    label: '4', value: '4',
}, {
    label: '3', value: '3',
}, {
    label: '2', value: '2',
}, {
    label: '1', value: '1',
},];

const stringsOptionsValues = stringsOptions.map((value) => {
    return value['value'];
})

const defaultCheckedStringsOptions = ['6', '5']

const MIN_FRET = 0;
const MAX_FRET = 24;
const defaultMinFret = 0;
const defaultMaxFret = 12;
export default function FretboardToPitchName() {
    const [inputQuestionNumber, setInputQuestionNumber] = useState(defaultInputQuestionNumber);
    const [questionNumber, setQuestionNumber] = useState(0);
    const [checkedStringsOptions, setCheckedStringsOptions] = useState(defaultCheckedStringsOptions);
    const [minFret, setMinFret] = useState(defaultMinFret);
    const [maxFret, setMaxFret] = useState(defaultMaxFret);
    const [usedTime, setUsedTime] = useState(0);
    const timer = useRef();

    return (<div>
            <Space style={{marginBottom: '10px'}}>
                <Checkbox.Group options={stringsOptions} value={checkedStringsOptions}
                                onChange={(checkedValue) => {
                                    setCheckedStringsOptions(checkedValue);
                                }}/>弦
                <Button onClick={() => {
                    setCheckedStringsOptions(stringsOptionsValues);
                }}>全选</Button>
                <Button onClick={() => {
                    let options = [...stringsOptionsValues];
                    options = options.filter((item) => {
                        return !checkedStringsOptions.includes(item)
                    })
                    setCheckedStringsOptions(options);
                }}>反选</Button></Space>
            <br/>
            <Space style={{marginBottom: '10px'}}>

                <InputNumber
                    min={MIN_FRET}
                    max={maxFret}
                    value={minFret}
                    onChange={setMinFret}
                /> -
                <InputNumber
                    min={minFret}
                    max={MAX_FRET}
                    value={maxFret}
                    onChange={setMaxFret}
                />品
            </Space>
            <br/>
            <Space style={{marginBottom: '10px'}}>
                <InputNumber
                    min={1}
                    max={1000}
                    defaultValue={defaultInputQuestionNumber}
                    onChange={setInputQuestionNumber}
                /> 个问题
                {questionNumber === 0 ? (<Button
                    onClick={() => {
                        setQuestionNumber(inputQuestionNumber);
                        setUsedTime(0);
                        timer.current = setInterval(() => {
                            setUsedTime(usedTime => usedTime + 1)
                        }, 1000);
                    }}
                >开始
                </Button>) : (<Button danger={true} onClick={() => {
                    setQuestionNumber(0);
                    clearInterval(timer.current);
                    setUsedTime(0);
                }}>重置</Button>)}
            </Space>
            <br/>
            <Collapse defaultActiveKey={[]} bordered={false}>
                <Panel header="吉他指板音名" key="1">
                    <Image style={{width: "700px"}}
                           src={guitarNoteNamesImage} preview={false}
                    />
                </Panel>
            </Collapse>
            <Divider/>
            {questionNumber > 0 ? (
                <Test questionNumber={questionNumber} stringsOptions={checkedStringsOptions} minFret={minFret}
                      maxFret={[maxFret]} finishQuestion={() => {
                    setQuestionNumber(0);
                    clearInterval(timer.current);
                }}/>) : null}
            {questionNumber === 0 && usedTime > 0 ? <h3>{`完成，用时 ${usedTime} 秒`}</h3> : null}
        </div>

    )
}

const Test = (props) => {
    const checkAnswer = () => {
        if (inputAnswer === '') {
            return
        }
        const pitch = answerMap.filter((item) => {
            return item['string'] === currentString
        })[0]['frets'].filter((item) => {
            return item['fret'] === currentFret
        })[0]['pitch']
        setQuestionAnswered(true);
        setAnswerRight(pitch.includes(inputAnswer.toUpperCase()));
    }

    const nextQuestion = () => {
        setCurrentQuestionNumber(prevState => prevState + 1)
        setCurrentString(stringsOptions.getRandom());
        setCurrentFret(randomIntFromInterval(minFret, maxFret));
        setQuestionAnswered(false);
        setAnswerRight(false);
        setInputAnswer('');
    }

    const {questionNumber, stringsOptions, minFret, maxFret, finishQuestion} = props;

    const [currentQuestionNumber, setCurrentQuestionNumber] = useState(1);
    const [inputAnswer, setInputAnswer] = useState('');
    const [currentString, setCurrentString] = useState(stringsOptions.getRandom());
    const [currentFret, setCurrentFret] = useState(randomIntFromInterval(minFret, maxFret));
    const [questionAnswered, setQuestionAnswered] = useState(false)
    const [answerRight, setAnswerRight] = useState(false)
    useEffect(() => {
        if (currentQuestionNumber > questionNumber) {
            finishQuestion();
        }
    }, [currentQuestionNumber, questionNumber, finishQuestion]);

    return (<div>
        {currentQuestionNumber} / {questionNumber}
        <br/>
        <h2>{currentString + ' 弦 ' + currentFret + ' 品 '}</h2>
        <Space> <Input style={{width: '100px'}} value={inputAnswer} onChange={(e) => {
            setInputAnswer(e.target.value)
        }} onPressEnter={() => {
            if (!questionAnswered || !answerRight) {
                checkAnswer();
            } else {
                nextQuestion();
            }
        }}/>
            {questionAnswered && answerRight ? (currentQuestionNumber === questionNumber ?
                    <Button onClick={nextQuestion}>完成</Button> : <Button onClick={nextQuestion}>下一题</Button>) :
                <Button onClick={checkAnswer}>检查</Button>}
            {questionAnswered && answerRight ? <CheckCircleTwoTone twoToneColor="#52c41a"/> : null}
            {questionAnswered && !answerRight ? <CloseCircleTwoTone twoToneColor="#eb2f96"/> : null}
        </Space>
    </div>);
}

