const answerMap = [
    {
        string: '6', frets: [
            {fret: 0, pitch: ['E']},
            {fret: 1, pitch: ['F']},
            {fret: 2, pitch: ['F#', 'Gb']},
            {fret: 3, pitch: ['G']},
            {fret: 4, pitch: ['G#', 'Ab']},
            {fret: 5, pitch: ['A']},
            {fret: 6, pitch: ['A#', 'Bb']},
            {fret: 7, pitch: ['B']},
            {fret: 8, pitch: ['C']},
            {fret: 9, pitch: ['C#', 'Db']},
            {fret: 10, pitch: ['D']},
            {fret: 11, pitch: ['D#', 'Eb']},
            {fret: 12, pitch: ['E']},
            {fret: 13, pitch: ['F']},
            {fret: 14, pitch: ['F#', 'Gb']},
            {fret: 15, pitch: ['G']},
            {fret: 16, pitch: ['G#', 'Ab']},
            {fret: 17, pitch: ['A']},
            {fret: 18, pitch: ['A#', 'Bb']},
            {fret: 19, pitch: ['B']},
            {fret: 20, pitch: ['C']},
            {fret: 21, pitch: ['C#', 'Db']},
            {fret: 22, pitch: ['D']},
            {fret: 23, pitch: ['D#', 'Eb']},
            {fret: 24, pitch: ['E']},
        ],
    },
    {
        string: '5', frets: [
            {fret: 0, pitch: ['A']},
            {fret: 1, pitch: ['A#', 'Bb']},
            {fret: 2, pitch: ['B']},
            {fret: 3, pitch: ['C']},
            {fret: 4, pitch: ['C#', 'Db']},
            {fret: 5, pitch: ['D']},
            {fret: 6, pitch: ['D#', 'Eb']},
            {fret: 7, pitch: ['E']},
            {fret: 8, pitch: ['F']},
            {fret: 9, pitch: ['F#', 'Gb']},
            {fret: 10, pitch: ['G']},
            {fret: 11, pitch: ['G#', 'Ab']},
            {fret: 12, pitch: ['A']},
            {fret: 13, pitch: ['A#', 'Bb']},
            {fret: 14, pitch: ['B']},
            {fret: 15, pitch: ['C']},
            {fret: 16, pitch: ['C#', 'Db']},
            {fret: 17, pitch: ['D']},
            {fret: 18, pitch: ['D#', 'Eb']},
            {fret: 19, pitch: ['E']},
            {fret: 20, pitch: ['F']},
            {fret: 21, pitch: ['F#', 'Gb']},
            {fret: 22, pitch: ['G']},
            {fret: 23, pitch: ['G#', 'Ab']},
            {fret: 24, pitch: ['A']},
        ],
    },
    {
        string: '4', frets: [
            {fret: 0, pitch: ['D']},
            {fret: 1, pitch: ['D#', 'Eb']},
            {fret: 2, pitch: ['E']},
            {fret: 3, pitch: ['F']},
            {fret: 4, pitch: ['F#', 'Gb']},
            {fret: 5, pitch: ['G']},
            {fret: 6, pitch: ['G#', 'Ab']},
            {fret: 7, pitch: ['A']},
            {fret: 8, pitch: ['A#', 'Bb']},
            {fret: 9, pitch: ['B']},
            {fret: 10, pitch: ['C']},
            {fret: 11, pitch: ['C#', 'Db']},
            {fret: 12, pitch: ['D']},
            {fret: 13, pitch: ['D#', 'Eb']},
            {fret: 14, pitch: ['E']},
            {fret: 15, pitch: ['F']},
            {fret: 16, pitch: ['F#', 'Gb']},
            {fret: 17, pitch: ['G']},
            {fret: 18, pitch: ['G#', 'Ab']},
            {fret: 19, pitch: ['A']},
            {fret: 20, pitch: ['A#', 'Bb']},
            {fret: 21, pitch: ['B']},
            {fret: 22, pitch: ['C']},
            {fret: 23, pitch: ['C#', 'Db']},
            {fret: 24, pitch: ['D']},
        ],

    },
    {
        string: '3', frets: [
            {fret: 0, pitch: ['G']},
            {fret: 1, pitch: ['G#', 'Ab']},
            {fret: 2, pitch: ['A']},
            {fret: 3, pitch: ['A#', 'Bb']},
            {fret: 4, pitch: ['B']},
            {fret: 5, pitch: ['C']},
            {fret: 6, pitch: ['C#', 'Db']},
            {fret: 7, pitch: ['D']},
            {fret: 8, pitch: ['D#', 'Eb']},
            {fret: 9, pitch: ['E']},
            {fret: 10, pitch: ['F']},
            {fret: 11, pitch: ['F#', 'Gb']},
            {fret: 12, pitch: ['G']},
            {fret: 13, pitch: ['G#', 'Ab']},
            {fret: 14, pitch: ['A']},
            {fret: 15, pitch: ['A#', 'Bb']},
            {fret: 16, pitch: ['B']},
            {fret: 17, pitch: ['C']},
            {fret: 18, pitch: ['C#', 'Db']},
            {fret: 19, pitch: ['D']},
            {fret: 20, pitch: ['D#', 'Eb']},
            {fret: 21, pitch: ['E']},
            {fret: 22, pitch: ['F']},
            {fret: 23, pitch: ['F#', 'Gb']},
            {fret: 24, pitch: ['G']},
        ],
    },
    {
        string: '2', frets: [
            {fret: 0, pitch: ['B']},
            {fret: 1, pitch: ['C']},
            {fret: 2, pitch: ['C#', 'Db']},
            {fret: 3, pitch: ['D']},
            {fret: 4, pitch: ['D#', 'Eb']},
            {fret: 5, pitch: ['E']},
            {fret: 6, pitch: ['F']},
            {fret: 7, pitch: ['F#', 'Gb']},
            {fret: 8, pitch: ['G']},
            {fret: 9, pitch: ['G#', 'Ab']},
            {fret: 10, pitch: ['A']},
            {fret: 11, pitch: ['A#', 'Bb']},
            {fret: 12, pitch: ['B']},
            {fret: 13, pitch: ['C']},
            {fret: 14, pitch: ['C#', 'Db']},
            {fret: 15, pitch: ['D']},
            {fret: 16, pitch: ['D#', 'Eb']},
            {fret: 17, pitch: ['E']},
            {fret: 18, pitch: ['F']},
            {fret: 19, pitch: ['F#', 'Gb']},
            {fret: 20, pitch: ['G']},
            {fret: 21, pitch: ['G#', 'Ab']},
            {fret: 22, pitch: ['A']},
            {fret: 23, pitch: ['A#', 'Bb']},
            {fret: 24, pitch: ['B']},
        ],
    },
    {
        string: '1', frets: [
            {fret: 0, pitch: ['E']},
            {fret: 1, pitch: ['F']},
            {fret: 2, pitch: ['F#', 'Gb']},
            {fret: 3, pitch: ['G']},
            {fret: 4, pitch: ['G#', 'Ab']},
            {fret: 5, pitch: ['A']},
            {fret: 6, pitch: ['A#', 'Bb']},
            {fret: 7, pitch: ['B']},
            {fret: 8, pitch: ['C']},
            {fret: 9, pitch: ['C#', 'Db']},
            {fret: 10, pitch: ['D']},
            {fret: 11, pitch: ['D#', 'Eb']},
            {fret: 12, pitch: ['E']},
            {fret: 13, pitch: ['F']},
            {fret: 14, pitch: ['F#', 'Gb']},
            {fret: 15, pitch: ['G']},
            {fret: 16, pitch: ['G#', 'Ab']},
            {fret: 17, pitch: ['A']},
            {fret: 18, pitch: ['A#', 'Bb']},
            {fret: 19, pitch: ['B']},
            {fret: 20, pitch: ['C']},
            {fret: 21, pitch: ['C#', 'Db']},
            {fret: 22, pitch: ['D']},
            {fret: 23, pitch: ['D#', 'Eb']},
            {fret: 24, pitch: ['E']},
        ],
    },
]

export default answerMap;